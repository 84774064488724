import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f87c544fffe9802ed0f80d562f759209@o4504251313225728.ingest.us.sentry.io/4507617087782912",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [/\//, "play.lengbear99.com"],
    }),
    // Sentry.anrIntegration({ captureStackTrace: true })
  ],
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name === "/home") {
      return 0.5;
    }
    return 0.1;
  },
});
